import { useEffect, useState } from 'react';
import './App.css';
import { useAuth0 } from '@auth0/auth0-react';
import { requestEmbed } from './utils/api';
import { embedDashboard } from './utils/quicksight';
import SessionTimeoutDiv from './components/SessionTimeout';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { checkIAAuth0UserRole } from './utils/auth0';
import LoadingSpinner from './components/LoadingSpinner';
import useUserFlow from './components/useUserFlow/useUserFlow';

function IADashboard() {
  document.title = 'Spend Analytics Dashboard - Preview';
  const [auth0Token, setAuth0Token] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const { getAccessTokenSilently, user } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);

  checkIAAuth0UserRole(user);

  async function embedDashboardWithToken(auth0Token: string) {
    const qcResponse = await requestEmbed('dashboard', auth0Token);
    const dashboardUrl = qcResponse.dashboard_embed_url;

    embedDashboard('invoice-analytics-dashboard-preview', dashboardUrl);
    setIsLoading(false);
  }
  useUserFlow();
  // run only on once
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 15000);

    const fetchAccessToken = async () => {
      const token = await getAccessTokenSilently();
      setAuth0Token(token);
      embedDashboardWithToken(token);
    };
    fetchAccessToken().catch(console.error);
  }, [getAccessTokenSilently]);

  return (
    <>
      <SessionTimeoutDiv />
      <div className="flex min-h-screen h-full flex-col text-sm">
        <div className="flex w-full pt-[48px] sm:pt-0">
          <div className="w-full">
            <div className="mx-auto px-4 max-w-4xl text-center">
              <h1 className="text-2xl font-semibold tracking-tight text-gray-900">
                Spend Analytics Dashboard - Preview
              </h1>
              <p className="mt-4 text-base leading-6 text-gray-600">
                Spend Analytics offers clients who use our employment services
                with valuable insights on individual and aggregated invoice
                information, including cost breakdown by country and employee
              </p>
            </div>
            <div className="flex-1">
              {showWarning && (
                <div className="flex items-center bg-amethyst mx-4 my-3 px-6 py-2.5 sm:before:flex-1">
                  <p className="text-sm leading-6 text-white">
                    <a href="#">
                      <strong className="font-semibold">Attention: </strong>
                      This is a preview dashboard showcasing data from a sample
                      demo client. Please note that the information presented
                      here is for demonstration purposes only.
                    </a>
                  </p>
                  <div className="flex flex-1 justify-end">
                    <button
                      type="button"
                      className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
                      onClick={() => {
                        setShowWarning(false);
                      }}
                    >
                      <span className="sr-only">Dismiss</span>
                      <XMarkIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div>
              )}

              <LoadingSpinner isLoading={isLoading} />
               <div
                className="flex flex-col items-center justify-center"
                style={{ minWidth: '360px' }}
              >
                <div
                  className="min-w-full p-4"
                  id="invoice-analytics-dashboard-preview"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IADashboard;
