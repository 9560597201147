// https://developer.auth0.com/resources/guides/spa/react/basic-authentication
import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions,
} from '@auth0/auth0-react';
import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from '../constants';

const Auth0ProviderWithRedirectCallback = ({
  children,
  ...props
}: PropsWithChildren<Auth0ProviderOptions>) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState?: AppState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

export const Auth0ProviderWithNavigate = ({ children }: any) => {
  const subpage = window.location.pathname.substring(1); // Get the current subpage
  const redirectUri = window.location.origin + '/' + subpage;
  return (
    <Auth0ProviderWithRedirectCallback
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: AUTH0_AUDIENCE,
        redirect_uri: redirectUri,
        scope: 'profile email read:iadashboard',
      }}
      // cacheLocation="localstorage"
    >
      {children}
    </Auth0ProviderWithRedirectCallback>
  );
};
