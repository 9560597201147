import { useEffect, useState } from 'react';
import './App.css';
import { useAuth0 } from '@auth0/auth0-react';
import { requestEmbed } from './utils/api';
import { embedQ } from './utils/quicksight';
import SessionTimeoutDiv from './components/SessionTimeout';
import { checkIAAuth0UserRole } from './utils/auth0';

function IAQuestions() {
  const [auth0Token, setAuth0Token] = useState('');
  const { getAccessTokenSilently, user } = useAuth0();
  const [qBar, setQBar] = useState<any>();

  checkIAAuth0UserRole(user);

  async function embedQDashboardWithToken(auth0Token: string) {
    // console.log(auth0Token);
    const qcResponse = await requestEmbed('q_topic', auth0Token);
    const qUrl = qcResponse.q_embed_url;
    const qBar = embedQ('invoice-analytics-questions-preview', qUrl);
    setQBar(qBar);
  }

  function submitQQuestion(text: string) {
    qBar.setQBarQuestion(text);
  }

  // run only on once
  useEffect(() => {
    document.title = 'Invoice Analytics - Questions';
    const fetchAccessToken = async () => {
      const token = await getAccessTokenSilently();
      setAuth0Token(token);
      embedQDashboardWithToken(token);
    };
    fetchAccessToken().catch(console.error);
  }, [getAccessTokenSilently]);

  const items1 = ['Give me average salary'];
  const items2 = ['XXXXX'];
  const items3 = ['XXXXXX'];

  return (
    <div>
      <div></div>
      {/*
      <SessionTimeoutDiv />
      <div
        id="invoice-analytics-questions-preview"
        className="fixed-height text-center position-relative"
      >
        <div
          className="z-n1 position-absolute top-50 start-50 translate-middle bg-body-tertiary
              p-5 w-80 border shadow-sm rounded-2 text-start"
          style={{ minWidth: '360px' }}
        >
          <h4>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              className="bi bi-globe-americas text-amethyst"
              viewBox="0 0 20 20"
            >
              <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484-.08.08-.162.158-.242.234-.416.396-.787.749-.758 1.266.035.634.618.824 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.243-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z" />
            </svg>{' '}
            Invoice Analytics - Preview
          </h4>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-bar-chart-line-fill text-amethyst"
            viewBox="0 1 18 18"
          >
            <path d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2z" />
          </svg>{' '}
          Discover insights into invoice analytics with our conversational query
          service
          <br />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-question-circle-fill text-amethyst"
            viewBox="0 0 18 18"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
          </svg>{' '}
          Ask questions to uncover talent market trends worldwide or switch to
          the Global Dashboard
          <br />
          <div className="alert bg-light-ash mt-3 small" role="alert">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-info-circle-fill text-indigo"
              viewBox="0 0 18 18"
            >
              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
            </svg>{' '}
            Preview dataset includes limited number of employees (~12000) from
            2013-2023
            <br />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-info-circle-fill text-indigo"
              viewBox="0 0 18 18"
            >
              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
            </svg>{' '}
            &quot;Job Title&quot; is masked, and many fields are removed from
            the Preview dataset
            <br />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-info-circle-fill text-indigo"
              viewBox="0 0 18 18"
            >
              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
            </svg>{' '}
            Industry classification is based on the Standard Occupational
            Classification (SOC) code system
          </div>
          <p className="mt-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-hand-index-thumb-fill text-amethyst"
              viewBox="0 0 18 18"
            >
              <path d="M8.5 1.75v2.716l.047-.002c.312-.012.742-.016 1.051.046.28.056.543.18.738.288.273.152.456.385.56.642l.132-.012c.312-.024.794-.038 1.158.108.37.148.689.487.88.716.075.09.141.175.195.248h.582a2 2 0 0 1 1.99 2.199l-.272 2.715a3.5 3.5 0 0 1-.444 1.389l-1.395 2.441A1.5 1.5 0 0 1 12.42 16H6.118a1.5 1.5 0 0 1-1.342-.83l-1.215-2.43L1.07 8.589a1.517 1.517 0 0 1 2.373-1.852L5 8.293V1.75a1.75 1.75 0 0 1 3.5 0z" />
            </svg>{' '}
            Click on one of the questions below, or ask a question using a
            similar structure
          </p>
        </div>
      </div>
      {qBar && (
        <div className="card-group small ms-3 me-3">
          <div className="card m-3 border">
            <div className="card-header text-white bg-success">
              HR/TA Questions
            </div>
            <ul className="list-group list-group-flush">
              {items1.map((item, index) => (
                <li
                  key={index}
                  className="list-group-item"
                  role="button"
                  onClick={() => submitQQuestion(item)}
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <div className="card m-3 border">
            <div className="card-header text-white bg-amethyst">
              Finance Questions
            </div>
            <ul className="list-group list-group-flush">
              {items2.map((item, index) => (
                <li
                  key={index}
                  className="list-group-item"
                  role="button"
                  onClick={() => submitQQuestion(item)}
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <div className="card m-3 border">
            <div className="card-header text-white bg-primary">
              Data Analysis Questions
            </div>
            <ul className="list-group list-group-flush">
              {items3.map((item, index) => (
                <li
                  key={index}
                  className="list-group-item"
                  role="button"
                  onClick={() => submitQQuestion(item)}
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )} */}
    </div>
  );
}

export default IAQuestions;
