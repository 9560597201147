import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import userflow from "userflow.js";
import { USERFLOW_TOKEN } from "../../constants";

export type MappedUser = {
  appMetadata?: unknown | { features: Array<any | string> };
  company?: string;
  country?: string;
  email?: string;
  firstName?: string;
  fullName?: string;
  id?: string;
  lastName?: string;
  phone?: string;
  requestedApps?: Array<any | string>;
  roles?: Array<string>;
  avatar?: string;
  nickname?: string;
};

const extractFirstName = (name?: string): string | undefined => {
  if (!name) {
    return undefined;
  }
  return name.split(" ")[0];
};

const userMapper = (user: any): MappedUser => ({
  appMetadata: user?.["https://safeguardglobal.com/app_metadata"],
  country: user?.["https://safeguardglobal.com/user_metadata"]?.country,
  email: user?.email as string,
  firstName: extractFirstName(user?.given_name || user?.nickname || user?.name),
  fullName: user?.given_name || user?.nickname || user?.name,
  lastName: user?.family_name,
  id: user?.sub as string,
  phone: user?.["https://safeguardglobal.com/user_metadata"]?.phone_number,
  company: user?.["https://safeguardglobal.com/user_metadata"]?.company_name,
  roles: user?.["https://safeguardglobal.com/roles"],
  avatar: user?.picture,
  nickname: user?.nickname,
});


const   useUserFlow = () => {

  const { user } = useAuth0();
  const mappedUser = user ? userMapper(user) : undefined;

  useEffect(() => {

    if (USERFLOW_TOKEN) {
      userflow.init(USERFLOW_TOKEN as string);
      if (mappedUser) {
        userflow.identify(mappedUser?.id as string, {
          name: mappedUser?.fullName,
          email: mappedUser?.email,
          signed_up_at: new Date().toISOString(),
        });
      } else {
        userflow.identifyAnonymous({
          website_lead: true,
        });
      }
    } else {
      console.warn("Userflow token not found, skipping userflow initialization.");
    }
  }, [mappedUser]);
};

export default useUserFlow;
