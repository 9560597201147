import React from 'react';


interface LoadingSpinnerProps {
    isLoading: boolean;
}

const loading_spinner = "/spinner.png";

const LoadingSpinner: React.FC<LoadingSpinnerProps> = (props) => {

    const { isLoading } = props;
    const state = { speed: 3 };
    if (isLoading) {
        return (
            <div className="loading-box flex items-center justify-center" style={{ marginTop: '50px' }}>
                <div className="w-[634px] h-[250px] p-[50px] bg-white rounded-[20px] shadow flex-col justify-start items-center gap-[33px] inline-flex">
                    <div className="self-stretch h-[120px] flex-col justify-center items-center gap-[25px] flex">
                        <div className="spinner h-[68px]">
                            <img style={{ animation: `spin ${state.speed}s linear infinite` }} src={loading_spinner} alt="img" />
                        </div>
                        <div className="justify-center items-center gap-[15px] inline-flex">

                            <div className="text-center text-gray-600 text-xl font-bold font-['Inter']">Loading your dashboard... Sit tight!</div>
                            <div className="w-6 h-6 relative" />
                        </div>
                        <div className="self-stretch text-center text-gray-600 text-base font-normal font-['Inter']">We are fetching the latest data to provide you with the most up-to-date insights</div>
                    </div>
                </div>
            </div>
        );
    }
    return (<></>);
};

export default LoadingSpinner;
