import Sidebar from 'global-sidebar';
import Header from 'global-header';
import Footer from './Footer';
import { useAuth0 } from '@auth0/auth0-react';
import { CONTENTFUL_SPACE_ID, CONTENTFUL_ACCESS_TOKEN } from '../constants';

import "global-sidebar/dist/index.css";
import "global-header/dist/index.css";

interface LayoutProps {
  children: React.ReactNode;
  activeSubpage: string;
}

export default function Layout(props: React.PropsWithChildren<LayoutProps>) {
  const { children } = props;
  const { user, isAuthenticated, logout } = useAuth0();

  const displayName = user
    ? user?.given_name || user?.nickname || user?.name || "Guest"
    : "Guest";
  const email = user?.email ? user.email : "";
  const avatar = user?.picture;

  return (
    <div>
      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        <Sidebar contentful={{ space: CONTENTFUL_SPACE_ID, accessToken: CONTENTFUL_ACCESS_TOKEN }} />
      </div>

      <div className="lg:pl-72 min-w-fit">
        <Header
          showLogo={false}
          user={isAuthenticated ? {
            name: displayName,
            email: email,
            picture: avatar
          } : undefined}
          onSignoutClick={() => logout()}
        />

        <main className="py-10 2xl:px-28">{children}</main>

        <Footer />
      </div>
    </div>
  );
}
