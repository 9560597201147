export default function Footer() {
  return (
    <footer className="bg-white mt-2 border-t border-gray-900/10 pt-2 md:flex md:items-center md:justify-between">
      <div className="w-full mx-auto p-4">
        <div className="sm:flex sm:items-center sm:justify-between">
          <a
            href="https://www.safeguardglobal.com"
            className="flex items-center mb-4 sm:mb-0"
          >
            <img
              src="https://www.safeguardglobal.com/wp-content/uploads/elementor/thumbs/safeguard-global-logo-pfln25jnwz0a36nmiztquf0nwsob2e772fxif3qeww.png"
              className="h-10 mr-3"
              alt="Safeguard Global Logo"
            />
          </a>
          <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
            <li>
              <a
                href="https://www.safeguardglobal.com/our-company/"
                className="mr-4 hover:text-[#472247] md:mr-6 "
              >
                About
              </a>
            </li>
            <li>
              <a
                href="https://www.safeguardglobal.com/privacy/"
                className="mr-4 hover:text-[#472247] md:mr-6"
              >
                Privacy
              </a>
            </li>
            <li>
              <a
                href="https://www.safeguardglobal.com/interact/contact-us/"
                className="hover:text-[#472247]"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
        <hr className="my-2 border-gray-200 sm:mx-auto border-gray-400" />
        <span className="block text-sm text-gray-500 sm:text-center text-gray-400">
        {'© '+new Date().getFullYear()+' '}
          <a
            href="https://www.safeguardglobal.com/"
            className="hover:underline"
          >
            Safeguard Global
          </a>
          . All Rights Reserved.
        </span>
      </div>
    </footer>
  );
}
